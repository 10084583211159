<template>
	<div class="content">
		<list-header />	
		<div class="content--loading" v-if="loading">
			<filling-circle color="#057DCD" :size="42" />
			</div>
			<div v-else class="category-display">
				<branch-list v-for="(branch,index) in getBranches" :subId="branch.id"  :name="branch.name" :logo="branch.logo" :location="branch.location" :key="index"/>
			</div>		
		</div>

	<router-view />
</template>

<script>

import BranchList from '@/components/Branches/Branch.vue'
import ListHeader from '@/components/ListHeader.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'

export default {
  components: { BranchList, ListHeader,FillingCircle},
  name: 'BranchesView',

  data(){
		return {
			loading: false,
		}
  },
  computed:{
	getBranches(){
		return this.$store.state.branches.branches
	},
	loadBranches(){
		return this.$store.dispatch({type:'branches/loadBranches'})
	}
},
mounted(){
	this.loading = true
	
	this.loadBranches.then(()=>{
		this.loading = false
	}).catch(()=>{
		this.loading = false
		// this.$store.commit({
		// 	type: 'addError',
		// 	error: error.message,
		// })
	})	
},

}

</script>
