<template>
    <div class="branch" @click="gotoBranch">
       <div class="branch__container">
            <details-button :subId="subId" routeToDetails="BranchEdit" />
            <div class="branch__image-container" :style="{'background-image':logoURL}">
            </div>
            <h1 class="branch__heading">{{ name }}</h1>
            <div class="branch__details">
                <p>Location:{{ location }}</p>
            </div>   
       </div>
	</div>
</template>
  
<script>
import DetailsButton from '@/components/DetailsButton.vue'
export default {
  name: 'BrachList',
  props:{
      subId: String,
      name: String,
      logo: String,
      location: String,
  },
  components: {
      DetailsButton,
  },
  computed:{
      logoURL(){
          return this.logo ? `url(https://api.vendoorr.com${this.logo})` : null
      }
  },
  methods:{    
      gotoBranch(){
          this.$store.commit({type:'setBranchId',branchId:this.subId})
          this.$router.push({name:'Product-Category',params:{ id: this.subId }})

      }
  },

}
</script>
